import type { SvgIconProps, TypographyProps } from "@mui/material";
import { Divider, Stack, Typography, colors } from "@mui/material";
import { cloneElement } from "react";
import type { ReactElement } from "react";
import type { FieldProps } from "react-admin";

export const FieldItem = ({
  label,
  icon,
  children,
  rightIcon,
  onRightIconClick,
}: {
  label: string;
  icon?: ReactElement;
  rightIcon?: ReactElement;
  onRightIconClick?: () => void;
  children: ReactElement;
}) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1} marginBottom={1}>
      {icon &&
        cloneElement<SvgIconProps>(icon, {
          htmlColor: colors.grey[700],
          fontSize: "medium",
        })}
      <Divider orientation="vertical" flexItem />
      <Stack direction="column">
        <Typography
          variant="overline"
          lineHeight={1.5}
          fontWeight="bold"
          color={colors.grey[700]}
        >
          {label}
        </Typography>
        <Stack direction="row">
          {cloneElement<TypographyProps & FieldProps>(children, {
            variant: "body1",
            emptyText: "-",
          })}

          {rightIcon &&
            cloneElement<SvgIconProps>(rightIcon, {
              htmlColor: onRightIconClick ? colors.blue[600] : colors.grey[300],
              onClick: onRightIconClick,
            })}
        </Stack>
      </Stack>
    </Stack>
  );
};
