export interface ResidenceContact {
  id: string;
  type: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  company?: string;
  notes?: string;
  residences: string[];
  organizationId: string;
}

export const residenceContactTypes = [
  "Équipes de proximité (gardiens ou responsables de site)",
  "Chargés de clientèle ou SRC",
  "Responsable maintenance (interne au bailleur)",
  "Entreprise de maintenance (externe)",
  "Gestion locative",
  "Chef de projet",
];
