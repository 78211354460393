import { getStorage, localStorageStore } from "react-admin";
import type { Store } from "react-admin";
import { dataProvider } from "./data";

const version = "1";
const appKey = "";

const localStorage = localStorageStore(version, appKey);
const prefix = `RaStore${appKey}.`;

export const userStore: Store = {
  setup: localStorage.setup,
  teardown: localStorage.teardown,
  getItem: localStorage.getItem,
  setItem: <T = any>(key: string, value: T) => {
    localStorage.setItem(key, value);
    saveConfig();
  },
  removeItem: (key: string) => {
    localStorage.removeItem(key);
    saveConfig();
  },
  removeItems: (keyPrefix: string) => {
    localStorage.removeItems(keyPrefix);
    saveConfig();
  },
  reset: localStorage.reset,
  subscribe: localStorage.subscribe,
};

const saveConfig = () => {
  const storage = getStorage();
  const config = Object.keys(storage).reduce((config, key) => {
    const scopedKey = key.replace(prefix, "");
    if (key.startsWith(prefix)) {
      config[scopedKey] = localStorage.getItem(scopedKey);
    }
    return config;
  }, {} as Record<string, any>);
  dataProvider.saveUserConfig(config).catch(() => {
    console.error(`Failed to save user config`, config);
  });
};

export const init = (config: Record<string, any>) => {
  for (const key of Object.keys(config)) {
    localStorage.setItem(key, config[key]);
  }
};
