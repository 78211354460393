import { Divider, Paper, Typography } from "@mui/material";
import { groupBy } from "lodash";
import { useRedirect } from "react-admin";
import { useQuery } from "react-query";
import { dataProvider } from "../../../providers/data";
import { Chart } from "../../Chart";

interface DeviceStatsProps {
  filter: any;
}

export const heatingStates = [
  {
    label: "Pas de calcairisation",
    description: "Chauffe rapide jusqu’à la T° max physique",
    color: "#4caf50",
    selector: "GOOD",
  },
  {
    label: "Calcairisation importante",
    description: "Chauffe lente à l’approche de la T° max physique",
    color: "#ff9800",
    selector: "BAD",
  },
  {
    label: "Calcairisation critique",
    description: "Chauffe très lente pendant tout le cycle de chauffe",
    color: "#ff5722",
    selector: "VERY_BAD",
  },
  {
    label: "Problème électrique",
    description: "Puissance constatée < 1000W",
    color: "#f44336",
    selector: "ELECTRICAL_ISSUE",
  },
  {
    label: "Indéterminé",
    description: "La calcairisation n'est pas encore calculable.",
    color: "#607d8b",
    selector: undefined,
  },
];
export const HeatersState: React.FC<DeviceStatsProps> = ({ filter }) => {
  const { data } = useQuery({
    queryFn: () => dataProvider.getHeatersState(filter),
    queryKey: [`heaters/state`, filter],
  });
  const redirect = useRedirect();

  const groups = groupBy(data?.data || [], (d) => {
    for (const category of heatingStates) {
      if (d.state === category.selector) {
        return category.label;
      }
    }
  });

  const options: Highcharts.Options = {
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          distance: -35,
          formatter: function () {
            return Math.round((this as any).percentage).toFixed(0) + "%";
          },
        },
        showInLegend: true,
        startAngle: -90,
        endAngle: 90,
        center: ["50%", "75%"],
        size: "110%",
      },
      series: {
        events: {
          click: (event) => {
            redirect(() => {
              const state = (event.point as any).custom.state;
              return `devices?filter=${JSON.stringify({
                ...filter,
                heater: {
                  state: state === undefined ? { $exists: false } : state,
                },
              })}`;
            });
          },
        },
      },
    },
    title: {
      text: "Calcairisation",
    },
    subtitle: {
      text: data && `D'après ${data.data.length} boitiers v2 installés`,
    },
    series: [
      {
        name: "Count",
        type: "pie",
        innerSize: "50%",
        tooltip: {
          headerFormat: "<b>{point.key}: {point.y} chauffe-eaux</b>",
          pointFormat: `<br />{point.custom.description}`,
        },
        data: heatingStates
          .filter((category) => {
            return groups[category.label];
          })
          .map((category) => ({
            name: `${category.label} (${category.description})`,
            y: groups[category.label]?.length ?? 0,
            custom: {
              state: category.selector,
              description: category.description,
            },
            color: category.color,
          })),
      },
    ],
  };

  return (
    <Paper elevation={1}>
      <Chart
        isLoading={!data}
        options={options}
        description={
          <>
            <Divider sx={{ color: "#607d8b", marginTop: 1 }}>
              Indéterminé
            </Divider>
            <Typography variant="body2">
              Afin de mesurer la calcairisation, des périodes de chauffes
              suffisamment longues (~1h) sont nécessaires. Un chauffe-eau peu
              utilisé, en cours d'observation peut par exemple expliquer une
              calcairisation inconnue.
            </Typography>

            <Divider sx={{ color: "#f44336", marginTop: 2 }}>
              Problème électrique
            </Divider>
            <Typography variant="body2">
              {"Puissance constatée < 1000W"}
            </Typography>
            <Typography fontWeight="bold" variant="body2">
              Conséquences
            </Typography>
            <Typography variant="body2">
              <ul>
                <li>Confort dégradé</li>
                <li>
                  Facteur consommation / confort sous-optimal (chauffe uniforme)
                </li>
                <li>Impossibilité de réaliser un choc thermique</li>
              </ul>
            </Typography>
            <Typography fontWeight="bold" variant="body2">
              Actions recommandées
            </Typography>
            <Typography variant="body2">Remplacement du chauffe-eau</Typography>

            <Divider sx={{ color: "#ff5722", marginTop: 2 }}>
              Calcairisation critique
            </Divider>
            <Typography variant="body2">
              Chauffe très lente pendant tout le cycle de chauffe.
            </Typography>
            <Typography fontWeight="bold" variant="body2">
              Conséquences
            </Typography>
            <Typography variant="body2">
              <ul>
                <li>Confort dégradé dû à une chauffe lente</li>
                <li>
                  Surconsommation majeure par augmentation des déperditions
                  liées au calcaire
                </li>
                <li>Réduction de la durée de vie du chauffe-eau</li>
                <li>Impossibilité de réaliser un choc thermique</li>
              </ul>
            </Typography>
            <Typography fontWeight="bold" variant="body2">
              Actions recommandées
            </Typography>
            <Typography variant="body2">Remplacement du chauffe-eau</Typography>

            <Divider sx={{ color: "#ff9800", marginTop: 2 }}>
              Calcairisation importante
            </Divider>
            <Typography variant="body2">
              Chauffe lente à l’approche de la T° max physique.
            </Typography>
            <Typography fontWeight="bold" variant="body2">
              Conséquences
            </Typography>
            <Typography variant="body2">
              <ul>
                <li>
                  Surconsommation due aux commutations autour la T° max physique
                </li>
                <li>
                  {
                    "Possible difficulté de réaliser un choc thermique (si T° max physique <65°)"
                  }
                </li>
              </ul>
            </Typography>
            <Typography fontWeight="bold" variant="body2">
              Actions recommandées
            </Typography>
            <Typography variant="body2">
              <ul>
                <li>Pilotage Elax</li>
                <li>Augmenter la température maximale physique</li>
                <li>Maintenance pour décalcairisation</li>
              </ul>
            </Typography>

            <Divider sx={{ color: "#4caf50", marginTop: 2 }}>
              Pas de calcairisation
            </Divider>
            <Typography variant="body2">
              Chauffe rapide jusqu’à la T° max physique
            </Typography>
            <Typography fontWeight="bold" variant="body2">
              Conséquences
            </Typography>
            <Typography variant="body2">
              <ul>
                <li>Chauffe rapide et optimale</li>
              </ul>
            </Typography>
          </>
        }
      />
    </Paper>
  );
};
