import { Grid } from "@mui/material";
import { ReferenceManyField } from "react-admin";
import { JsonParam, useQueryParam } from "use-query-params";
import DeploymentSummary from "../../../../intervention/reporting/DeploymentSummary";
import { RefusalReasonsStats } from "../../../../intervention/reporting/RefusalReasons";
import { SourcingStatusStats } from "../../../../intervention/reporting/SourcingStatus";
import { SourcingTypeStats } from "../../../../intervention/reporting/SourcingType";
import { InterventionType } from "../../../../intervention/types";
import { ListingImportField } from "../../../fields/ListingImportField";

export const InstallationReport = () => {
  const [filters] = useQueryParam("filters", JsonParam);

  return (
    <Grid container>
      <Grid item xs={12} marginBottom={2}>
        <ListingImportField />
      </Grid>
      <Grid item xs={12} marginBottom={3}>
        <DeploymentSummary entity="project" />
      </Grid>
      <ReferenceManyField
        reference="interventions"
        target="projectId"
        page={1}
        perPage={10_000}
        filter={{ type: InterventionType.INSTALLATION, ...filters }}
      >
        <Grid item md={4} xs={12}>
          <SourcingStatusStats entity="project" />
        </Grid>
        <Grid item md={4} xs={12}>
          <SourcingTypeStats entity="project" />
        </Grid>
        <Grid item md={4} xs={12}>
          <RefusalReasonsStats entity="project" />
        </Grid>
      </ReferenceManyField>
    </Grid>
  );
};
