import type { HousingStatus } from "../housing/types";

export interface Project {
  id: string;
  name: string;
  quotedDeviceCount?: number;
  listingSpreadsheetId?: string;
  lastListingImport?: {
    date: string;
    errorMessages: string[];
  };
  contacts: {
    role: "CSM" | "AM";
    email: string;
    name: string;
    picture: string | null;
  }[];
  calendlyUtmSource?: string;
  brevoListId?: number;
  isDeploymentLetterEnabled?: boolean;
  deploymentMaintenanceProviderId?: string;
  housingStats: {
    statuses: {
      [HousingStatus.EQUIPPED]: number;
      [HousingStatus.NOT_EQUIPPABLE]: number;
      [HousingStatus.NOT_EQUIPPED]: number;
      [HousingStatus.TAKEN_OFF]: number;
    };
    total: number;
  };
  deployment: {
    stage: ProjectStage;
    deploymentStartDate?: string;
    deploymentEndDate?: string;
  };
}

export enum ProjectStage {
  NOT_STARTED_YET = "NOT_STARTED_YET",
  DEPLOYMENT_PREPARATION = "DEPLOYMENT_PREPARATION",
  DEPLOYMENT_IN_PROGRESS = "DEPLOYMENT_IN_PROGRESS",
  DEPLOYMENT_DONE = "DEPLOYMENT_DONE",
}

export const projectStageLabels: Record<ProjectStage, string> = {
  [ProjectStage.NOT_STARTED_YET]: "non démarré",
  [ProjectStage.DEPLOYMENT_PREPARATION]: "déploiement en préparation",
  [ProjectStage.DEPLOYMENT_IN_PROGRESS]: "déploiement en cours",
  [ProjectStage.DEPLOYMENT_DONE]: "déploiement fini",
};
