import { Select, MenuItem, Stack, Box, InputLabel } from "@mui/material";
import { omit } from "lodash";
import {
  ReferenceManyField,
  Datagrid,
  TextField,
  ReferenceOneField,
  DateField,
  ShowButton,
  useRecordContext,
  Pagination,
} from "react-admin";
import { useQueryParam, JsonParam } from "use-query-params";
import { NameField } from "../../end-users/fields/NameField";
import { AppointmentMedium } from "../../end-users/type";
import { AddressField } from "../../housing/fields/Address";
import { DeploymentCommunicationsSentField } from "../../housing/fields/DeploymentCommunicationsSentField";
import { HousingLastInstallationField } from "../../housing/fields/HousingLastInstallationField";
import { HousingLastProjectField } from "../../housing/fields/HousingLastProjectField";
import { HousingStatusField } from "../../housing/fields/Status";
import type { Residence } from "../types";

export const ResidenceHousingList = () => {
  const residence = useRecordContext<Residence>();
  const lastProjectId = residence.projects.at(-1)?.projectId;
  const [filters, setFilters] = useQueryParam("filters", JsonParam);

  return (
    <>
      <Stack direction="row" alignItems="center">
        <Box>
          <InputLabel>
            Filter by deployment communication of latest project
          </InputLabel>
          <Select
            value={
              filters?.deploymentCommunications
                ? JSON.stringify(
                    omit(filters.deploymentCommunications, "projectId")
                  )
                : ""
            }
            onChange={(event) =>
              setFilters({
                ...filters,
                deploymentCommunications: event.target.value
                  ? {
                      projectId: lastProjectId,
                      ...JSON.parse(event.target.value),
                    }
                  : undefined,
              })
            }
            size="small"
            sx={{ minWidth: 200 }}
          >
            <MenuItem value="">&nbsp;</MenuItem>
            <MenuItem
              value={JSON.stringify({
                medium: AppointmentMedium.COURRIER,
                isSent: false,
              })}
            >
              Pas de lettre envoyée malgré éligibilité
            </MenuItem>
            <MenuItem
              value={JSON.stringify({
                medium: AppointmentMedium.EMAIL,
                isSent: false,
              })}
            >
              Pas d'email envoyé malgré éligibilité
            </MenuItem>
          </Select>
        </Box>
      </Stack>
      <ReferenceManyField
        reference="housings"
        target="residenceId"
        sort={{ field: "unitId", order: "ASC" }}
        filter={filters}
        pagination={<Pagination />}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField label="Unit ID" source="unitId" />
          <AddressField label="Address" />
          <ReferenceOneField
            reference="end-users"
            target="housingId"
            label="End User"
            sortable={false}
            link="show"
          >
            <NameField />
          </ReferenceOneField>
          <HousingStatusField label="Status" source="status" />
          <HousingLastInstallationField label="Equiped At">
            <DateField source="datetime" />
          </HousingLastInstallationField>
          <HousingLastInstallationField label="Planned At">
            <DateField source="plannedSlot.startDate" showTime />
          </HousingLastInstallationField>
          <HousingLastProjectField label="Last project" />
          {lastProjectId && (
            <DeploymentCommunicationsSentField
              label="Deployment letter sent"
              projectId={lastProjectId}
              communicationType="letter"
            />
          )}
          {lastProjectId && (
            <DeploymentCommunicationsSentField
              label="Deployment email sent"
              projectId={lastProjectId}
              communicationType="email"
            />
          )}
          <ShowButton />
        </Datagrid>
      </ReferenceManyField>
    </>
  );
};
