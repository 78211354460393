import Icon from "@mui/icons-material/LocalShipping";
import {
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNotify, useRefresh } from "react-admin";
import { useMutation } from "react-query";
import {
  ActionDialog,
  ActionDialogButton,
  DialogActionMenuItem,
} from "../../../../layout/actions-menu";
import { dataProvider } from "../../../../providers/data";

export const MovingsAction = () => {
  const [fileId, setFileId] = useState("");
  const notify = useNotify();
  const refresh = useRefresh();

  const { mutate: handleMovings } = useMutation<{ errors: string[] }>(
    ["declare-movings", fileId],
    async () => dataProvider.declareMovings(fileId),
    {
      onSuccess: (data) => {
        if (data.errors?.length) {
          notify(
            `Movings imported but errors occured: ${data.errors.join(", ")}`,
            { type: "error" }
          );
        } else {
          notify("Movings imported", { type: "success" });
        }
        refresh();
      },
      onError: (error: any) => {
        notify(`Failed to install device: ${error.message}`, {
          type: "error",
        });
      },
    }
  );

  return (
    <DialogActionMenuItem
      label="Declare multiple movings"
      icon={<Icon />}
      dialog={
        <ActionDialog>
          <DialogContent>
            <Typography variant="h5">Declare multiple movings</Typography>
            <TextField
              label="Google Spreadsheet URL"
              value={fileId}
              onChange={(event) => {
                setFileId(event.target.value);
              }}
              fullWidth
              sx={{ marginTop: 2 }}
              helperText="The file must have the following headers: unit ID, first name,
              last name, email, phone 1, phone 2"
            />
          </DialogContent>
          <DialogActions>
            <ActionDialogButton>Cancel</ActionDialogButton>
            <ActionDialogButton
              variant="contained"
              disabled={!fileId}
              onClick={(event, close) => {
                handleMovings();
                close();
              }}
            >
              Declare Movings
            </ActionDialogButton>
          </DialogActions>
        </ActionDialog>
      }
    />
  );
};
