import {
  AutocompleteInput,
  Datagrid,
  DateField,
  List,
  ReferenceField,
  ReferenceInput,
  ShowButton,
  TextField,
  TextInput,
} from "react-admin";
import { DateRangeFilter } from "../../filters/DateRange";
import MultiSelectFilter from "../../filters/MultiSelectFilter";
import { useSelectedColumns } from "../../layout/SelectColumns";
import { SelectProject } from "../devices/fields/SelectProject";
import { AddressField } from "../housing/fields/Address";
import { InterventionActions } from "./actions";
import { InterventionStatusReasonField } from "./fields/InterventionStatusReasonField";
import { KizeoField } from "./fields/kizeo";
import { InterventionStatusField } from "./fields/status";
import { InterventionTypeField } from "./fields/type";
import {
  InterventionStatus,
  InterventionStatusReason,
  InterventionType,
  interventionStatusLabels,
  interventionsStatusReasonLabels,
} from "./types";

export const InterventionList = () => {
  const columns = useSelectedColumns({
    preferences: "interventions.list.columns",
    columns: interventionColumns,
    omit: [],
    defaultColumns: [
      "datetime",
      "type",
      "status",
      "statusReason",
      "unitId",
      "device",
      "comment",
    ],
  });

  return (
    <List
      perPage={100}
      hasCreate={true}
      sort={{ field: "datetime", order: "DESC" }}
      actions={<InterventionActions />}
      filters={filters}
    >
      <Datagrid bulkActionButtons={false}>
        {columns}
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const interventionColumns = {
  createdAt: <DateField label="Creation Date" source="createdAt" showTime />,
  datetime: <DateField label="Date" source="datetime" showTime />,
  plannedDatetime: (
    <DateField label="Planned At" source="plannedSlot.startDate" showTime />
  ),
  type: <InterventionTypeField label="Type" />,
  status: <InterventionStatusField label="Status" />,
  statusReason: <InterventionStatusReasonField label="Status Reason" />,
  unitId: (
    <ReferenceField
      label="Housing"
      source="housingId"
      reference="housings"
      link="show"
      sortable={false}
    >
      <TextField source="unitId" />
      <AddressField />
    </ReferenceField>
  ),
  device: (
    <ReferenceField
      label="Device"
      source="deviceId"
      reference="devices"
      link="show"
      sortable={false}
    >
      <TextField source="name" />
    </ReferenceField>
  ),
  comment: <TextField label="Comment" source="comment" />,
  project: (
    <ReferenceField
      label="Project"
      source="projectId"
      reference="projects"
      link="show"
    >
      <TextField source="name" />
    </ReferenceField>
  ),
  landlord: (
    <ReferenceField source="projectId" reference="projects" label="Landlord">
      <ReferenceField
        source="organizationId"
        reference="organizations"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
    </ReferenceField>
  ),
  kizeo: <KizeoField label="Kizeo" />,
  maintenanceProvider: (
    <ReferenceField
      label="Maintenance Provider"
      source="maintenanceProviderId"
      reference="maintenance-providers"
      link={false}
      sortable={false}
    >
      <TextField source="name" />
    </ReferenceField>
  ),
};

const filters = [
  <MultiSelectFilter
    source="type"
    label="Type"
    options={Object.values(InterventionType).map((value) => ({
      value: value,
      label: value,
    }))}
  />,
  <ReferenceInput
    reference="maintenance-providers"
    source="maintenanceProviderId"
  >
    <AutocompleteInput
      label="Maintenainer"
      optionText="name"
      fullWidth
      size="small"
    />
  </ReferenceInput>,
  <MultiSelectFilter
    source="status"
    label="Status"
    options={Object.values(InterventionStatus).map((value) => ({
      value: value,
      label: interventionStatusLabels[value],
    }))}
  />,
  <MultiSelectFilter
    source="statusReason"
    label="Status Reason"
    options={Object.values(InterventionStatusReason).map((value) => ({
      value: value,
      label: interventionsStatusReasonLabels[value],
    }))}
  />,
  <DateRangeFilter source="createdAt" label="Creation Date" />,
  <DateRangeFilter source="datetime" label="Date" />,
  <DateRangeFilter source="plannedSlot.startDate" label="Planned At" />,
  <SelectProject source="projectId" label="Project" />,
  <TextInput source="comment" label="Comment" />,
];
