import { Tooltip } from "@mui/material";
import { useRecordContext } from "react-admin";
import type { Deal } from "../../deal/type";
import {
  HousingStatus,
  housingStatusColors,
  housingStatusLabels,
} from "../../housing/types";
import type { Project } from "../type";

export const DeploymentProgress: React.FC<{
  label?: string;
  width?: number;
  showCount?: boolean;
}> = ({ width, showCount }) => {
  const record = useRecordContext<Deal | Project>();

  // search is does not support enriched fields for now
  if (!record.housingStats) {
    return null;
  }

  return (
    <div
      style={{
        width: width ? `${width}px` : "100%",
        height: "28px",
        position: "relative",
        display: "flex",
        overflow: "hidden",
        borderRadius: 5,
      }}
    >
      {record &&
        [
          HousingStatus.NOT_EQUIPPED,
          HousingStatus.NOT_EQUIPPABLE,
          HousingStatus.EQUIPPED,
          HousingStatus.TAKEN_OFF,
        ].map((status) => (
          <Tooltip
            key={status}
            title={`${record.housingStats.statuses[status]}/${record.housingStats.total} ${housingStatusLabels[status]}`}
          >
            <div
              style={{
                width: `${
                  ((record.housingStats.statuses[status] ?? 0) /
                    (record.housingStats.total || 1)) *
                  100
                }%`,
                backgroundColor: housingStatusColors[status] + "aa",
                color: housingStatusColors[status],
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              {showCount === false
                ? null
                : record.housingStats.statuses[status] ?? 0}
            </div>
          </Tooltip>
        ))}
    </div>
  );
};
