import { Typography } from "@mui/material";
import {
  ReferenceField,
  ReferenceManyField,
  TextField,
  WithRecord,
} from "react-admin";
import FunctionList from "../../../../../display/FunctionList";
import { EndUserNameFieldWithLinks } from "../../../../end-users/fields/EndUserNameFieldWithLinks";
import type { EndUser } from "../../../../end-users/type";
import { AddressField } from "../../../../housing/fields/Address";
import EDEField from "../../../../housing/fields/EDEField";
import type { ResidenceContact } from "../../../../residence-contact/types";
import { DeviceProjectsField } from "../../../fields/DeviceProjectsField";
import InfoList from "../InfoList";
import { CommunicationSummary } from "./Communication";
import { InterventionSummary } from "./Intervention";

export default function HousingInfoList() {
  return (
    <InfoList label="Housing">
      <InfoList.Item label="End users">
        <ReferenceField reference="housings" source="housingId" link={false}>
          <ReferenceManyField reference="end-users" target="housingId">
            <FunctionList<EndUser>
              render={(endUser) => (
                <EndUserNameFieldWithLinks record={endUser} />
              )}
            />
          </ReferenceManyField>
        </ReferenceField>
      </InfoList.Item>

      <InfoList.Item label="Projects">
        <DeviceProjectsField />
      </InfoList.Item>

      <InfoList.Item label="Unit ID">
        <ReferenceField reference="housings" source="housingId" link="show">
          <TextField source="unitId" emptyText="-" variant="body1" />
        </ReferenceField>
      </InfoList.Item>

      <ReferenceField reference="housings" source="housingId" link={false}>
        <InfoList.Item label="Address">
          <AddressField />
        </InfoList.Item>

        <InfoList.Item label="Entité d'effacement">
          <EDEField />
        </InfoList.Item>
      </ReferenceField>

      <InfoList.Item label="Contacts Résidents">
        <ReferenceField reference="housings" source="housingId" link={false}>
          <ReferenceField
            reference="residences"
            source="residenceId"
            link={false}
          >
            <ReferenceManyField
              reference="residence-contacts"
              target="residences"
            >
              <FunctionList<ResidenceContact>
                render={(residenceContact) => (
                  <>
                    <Typography fontWeight="bold">
                      {residenceContact.type}
                    </Typography>
                    <Typography paddingLeft={2}>{`${
                      residenceContact.firstName ?? ""
                    } ${residenceContact.lastName ?? ""}`}</Typography>
                    <Typography paddingLeft={2}>{`${
                      residenceContact.phone ?? ""
                    } ${residenceContact.email ?? ""}`}</Typography>
                  </>
                )}
              />
            </ReferenceManyField>
          </ReferenceField>
        </ReferenceField>
      </InfoList.Item>

      <WithRecord
        render={(device) => (
          <InfoList.Item label="Interventions">
            <ReferenceManyField
              reference="interventions"
              target="deviceId"
              sort={{ field: "datetime", order: "DESC" }}
              perPage={1000}
            >
              <InterventionSummary device={device} />
            </ReferenceManyField>
          </InfoList.Item>
        )}
      />

      <InfoList.Item label="Communications">
        <ReferenceField reference="housings" source="housingId" link={false}>
          <ReferenceManyField
            reference="communications"
            target="context.housingId"
            perPage={1000}
            sort={{ field: "date", order: "DESC" }}
          >
            <CommunicationSummary />
          </ReferenceManyField>
        </ReferenceField>
      </InfoList.Item>
    </InfoList>
  );
}
