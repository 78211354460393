import { ActionsMenu, CreateAction } from "../../../../layout/actions-menu";
import { MovingsAction } from "./Movings";

export const HousingListActions = () => {
  return (
    <ActionsMenu>
      <CreateAction />
      <MovingsAction />
    </ActionsMenu>
  );
};
