import AlertIcon from "@mui/icons-material/Error";
import type { AlertColor } from "@mui/material";
import { AlertEdit } from "./Edit";
import { AlertList } from "./list/List";

export const alertResource = {
  list: AlertList,
  edit: AlertEdit,
  icon: AlertIcon,
};

export const alertSeverities: Record<string, AlertColor> = {
  "target-temperature": "warning",
  "valve-leakage": "warning",
  "msg-frequency": "error",
  "low-temperature": "warning",
  "broken-probe": "error",
  "no-heating": "error",
  legionellose: "info",
  "legionellose-schedule": "info",
  "legionellose-observation": "error",
  "electrical-issue": "error",
  "relay-power-ko": "error",
  absence: "info",
  "valve-obstruction": "warning",
  "trigger-alert": "warning",
  "too-many-reboots": "warning",
  switch: "error",
  "discomfort-alert": "info",
};

export { default as AlertIcon } from "@mui/icons-material/Error";
