import HomeIcon from "@mui/icons-material/Home";
import NotListedLocationIcon from "@mui/icons-material/NotListedLocation";
import PlaceIcon from "@mui/icons-material/Place";
import { Box, Divider, Grid, Modal, Typography } from "@mui/material";
import {
  CreateInDialogButton,
  EditInDialogButton,
} from "@react-admin/ra-form-layout";
import { useState } from "react";
import type { FC, ReactNode } from "react";
import {
  ArrayField,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  FunctionField,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  UrlField,
  WithRecord,
} from "react-admin";
import { ConsumptionExport } from "../../../actions/ConsumptionExport";
import { FieldGrid } from "../../../display/FieldGrid";
import { FieldItem } from "../../../display/FieldItem";
import { MarkdownPanel } from "../../../layout/MarkdownPanel";
import { ActionsMenu, EditAction } from "../../../layout/actions-menu";
import { RecordDetails } from "../../activity-logs/RecordDetails";
import { AssignFirmware } from "../../devices/bulk-actions/AssignFirmware";
import { ChangePilotConfig } from "../../devices/bulk-actions/edit-device-config";
import type { Organization } from "../../organization/types";
import { ResidenceContactEditionForm } from "../../residence-contact/Edit";
import type { ResidenceContact } from "../../residence-contact/types";
import { ResidenceAddressField } from "../fields/ResidenceAddressField";
import type { Residence } from "../types";
import { ResidenceHousingList } from "./ResidenceHousingList";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const ModalBody: FC<{ children: ReactNode }> = ({ children }) => (
  <Box sx={style}>{children}</Box>
);

export const ResidenceShow = () => {
  const [isPlaceOpen, setIsPlaceOpen] = useState(false);
  return (
    <Show actions={<ResidenceActions />}>
      <FunctionField<Residence>
        render={(residence) => (
          <>
            <Modal open={isPlaceOpen} onClose={() => setIsPlaceOpen(false)}>
              <ModalBody>
                <div>
                  {residence?.location ? (
                    <img
                      src={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+FF5733(${residence.location.coordinates[0]},${residence.location.coordinates[1]})/${residence.location.coordinates[0]},${residence.location.coordinates[1]},11,0/400x300?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`}
                      alt="location"
                    />
                  ) : (
                    "Missing location."
                  )}
                </div>
              </ModalBody>
            </Modal>
            <SimpleShowLayout>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <ReferenceField
                    reference="organizations"
                    source="organizationId"
                  >
                    <FunctionField<Organization>
                      render={(record) =>
                        record && (
                          <Typography variant="h4">{record.name}</Typography>
                        )
                      }
                    />
                  </ReferenceField>
                  <Typography variant="h4">{residence.esi}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <FieldGrid>
                    <FieldItem
                      label="Address"
                      icon={<HomeIcon />}
                      rightIcon={
                        residence?.location ? (
                          <PlaceIcon />
                        ) : (
                          <NotListedLocationIcon />
                        )
                      }
                      onRightIconClick={
                        residence?.location
                          ? () => setIsPlaceOpen(true)
                          : undefined
                      }
                    >
                      <ResidenceAddressField />
                    </FieldItem>
                  </FieldGrid>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6">Projects</Typography>
                  <ArrayField source="projects">
                    <Datagrid bulkActionButtons={false}>
                      <ReferenceField
                        reference="projects"
                        source="projectId"
                        label="Project"
                      >
                        <TextField source="name" />
                      </ReferenceField>
                      <UrlField source="calendlyUrl" label="Calendly URL" />
                      <TextField
                        source="installerPhone"
                        label="Installer phone"
                      />
                      <DateField
                        source="deploymentStartFormattedDate"
                        label="Deployment start date"
                      />
                      <DateField
                        source="deploymentEndFormattedDate"
                        label="Deployment end date"
                      />
                    </Datagrid>
                  </ArrayField>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6">Contacts</Typography>
                  <ReferenceManyField
                    reference="residence-contacts"
                    target="residences"
                    sort={{ field: "type", order: "ASC" }}
                    perPage={1000}
                  >
                    <WithRecord
                      render={(record: Residence) =>
                        record.isDeploymentActive ? (
                          <Typography variant="caption" fontStyle="italic">
                            A deployment is currently active, contacts can only
                            be imported through listing.
                          </Typography>
                        ) : (
                          <CreateInDialogButton
                            record={{
                              residences: [record.id],
                              organizationId: record.organizationId,
                            }}
                          >
                            <ResidenceContactEditionForm />
                          </CreateInDialogButton>
                        )
                      }
                    />
                    <Datagrid
                      bulkActionButtons={false}
                      expand={<MarkdownPanel source="notes" />}
                      isRowExpandable={(record) => record.notes}
                    >
                      <TextField label="Type" source="type" />
                      <TextField label="Job title" source="jobTitle" />
                      <FunctionField<ResidenceContact>
                        label="Name"
                        render={(record) =>
                          (record?.firstName || record?.lastName) &&
                          `${record.firstName || ""} ${record.lastName || ""}`
                        }
                      />
                      <TextField label="Email" source="email" />
                      <TextField label="Phone" source="phone" />
                      <TextField label="Company" source="company" />
                      <div style={{ display: "flex" }}>
                        <EditInDialogButton label="">
                          <ResidenceContactEditionForm />
                        </EditInDialogButton>
                        <DeleteWithConfirmButton
                          redirect={false}
                          label=""
                          confirmTitle="Voulez-vous vraiment supprimer ce contact ?"
                          confirmContent="Il va être supprimé pour toutes les résidences auxquelles il est rattaché. Si vous voulez seulement l'enlever de cette résidence, éditez-le et supprimez la résidence en question."
                        />
                      </div>
                      <RecordDetails />
                    </Datagrid>
                  </ReferenceManyField>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Housings</Typography>
                  <ResidenceHousingList />
                </Grid>
              </Grid>
            </SimpleShowLayout>
          </>
        )}
      />
    </Show>
  );
};

const ResidenceActions = () => {
  return (
    <ActionsMenu>
      <EditAction />
      <Divider />
      <ConsumptionExport />
      <Divider />
      <AssignFirmware />
      <ChangePilotConfig />
    </ActionsMenu>
  );
};
