import { EnumField } from "../../../fields/EnumField";
import {
  interventionsStatusReasonColors,
  interventionsStatusReasonLabels,
} from "../types";

export const InterventionStatusReasonField = ({
  label,
}: {
  label?: string;
}) => (
  <EnumField
    label={label || "Status Reason"}
    source="statusReason"
    colors={interventionsStatusReasonColors}
    labels={interventionsStatusReasonLabels}
  />
);
