import {
  AutocompleteInput,
  Datagrid,
  EditButton,
  FilterButton,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  ShowButton,
  TextField,
} from "react-admin";
import { PipedriveLink } from "../../fields/PipedriveLink";
import { DateRangeFilter } from "../../filters/DateRange";
import { MarkdownPanel } from "../../layout/MarkdownPanel";
import { ActionsMenu } from "../../layout/actions-menu";
import { SyncPipedriveButton } from "./actions/SyncProjects";
import { ProjectContactsField } from "./fields/Contacts";
import { DeploymentProgress } from "./fields/DeploymentProgress";
import { ListingLink } from "./fields/Listing";
import { ProjectDeploymentEndDate } from "./fields/ProjectDeploymentEndDate";
import { ProjectDeploymentStartDate } from "./fields/ProjectDeploymentStartDate";
import { ProjectStageField } from "./fields/Status";

const projectFilters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput
    label="Client"
    placeholder="Client"
    source="organizationId"
    reference="organizations"
    sort={{ field: "name", order: "ASC" }}
    perPage={500}
  >
    <AutocompleteInput label="Client" optionText="name" />
  </ReferenceInput>,
  <DateRangeFilter
    label="Deployment Start Date"
    source="deploymentStartDate"
  />,
  <DateRangeFilter label="Deployment End Date" source="deploymentEndDate" />,
  <ReferenceInput
    label="Deployment Maintenance Provider"
    placeholder="Maintenance Provider"
    source="deploymentMaintenanceProviderId"
    reference="maintenance-providers"
    sort={{ field: "name", order: "ASC" }}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

export const ProjectList = () => {
  return (
    <List
      perPage={100}
      hasCreate={true}
      sort={{ field: "createdAt", order: "DESC" }}
      filters={projectFilters}
      actions={
        <>
          <FilterButton />
          <ActionsMenu>
            <SyncPipedriveButton />
          </ActionsMenu>
        </>
      }
    >
      <Datagrid
        bulkActionButtons={false}
        expand={<MarkdownPanel source="notes" />}
        isRowExpandable={(record) => record?.notes}
      >
        <TextField label="Name" source="name" />
        <PipedriveLink
          source="pipedriveId"
          resource="projects"
          label="Pipedrive"
        />
        <ListingLink label="Listing" />
        <ReferenceField
          label="Client"
          reference="organizations"
          source="organizationId"
          link="show"
        >
          <TextField label="Name" source="name" />
        </ReferenceField>
        <ProjectStageField label="Status" />
        <DeploymentProgress label="Deployment" showCount={false} />
        <ProjectDeploymentStartDate label="Deployment Start Date" />
        <ProjectDeploymentEndDate label="Deployment End Date" />
        <NumberField label="Housings" source="housingStats.total" />
        <ProjectContactsField />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};
