import EndUserIcon from "@mui/icons-material/AccountBox";
import { EndUserEdit } from "./Edit";
import { EndUserList } from "./List";
import { EndUserShow } from "./Show";

export const endUserResource = {
  icon: EndUserIcon,
  list: EndUserList,
  edit: EndUserEdit,
  show: EndUserShow,
  options: { label: "End Users" },
};

export { default as EndUserIcon } from "@mui/icons-material/AccountBox";
