import DebugIcon from "@mui/icons-material/BugReport";
import RecoveryIcon from "@mui/icons-material/HealthAndSafety";
import HardResetIcon from "@mui/icons-material/PowerSettingsNew";
import StatusIcon from "@mui/icons-material/QueryStats";
import SecurityIcon from "@mui/icons-material/Security";
import FOTAIcon from "@mui/icons-material/Upgrade";
import { Button, Tooltip } from "@mui/material";
import { useRecordContext } from "react-admin";
import { satisfies } from "semver";
import { useNotifyAction } from "../../../../actions/useNotifyAction";
import { ActionMenuItem } from "../../../../layout/actions-menu";
import { dataProvider } from "../../../../providers/data";

const KICKMAKER_DEVICE_IMEI = 869_410_050_137_851;

export const HardResetAction = () => {
  const record = useRecordContext();

  const { mutate } = useNotifyAction(
    () =>
      dataProvider.sendConfigFlags(record!.id as string, {
        ReqHardReset: true,
      }),
    { successMessage: "Hard reset requested" }
  );

  if (record.majorHWVersion === 1) {
    return null;
  }

  return (
    <ActionMenuItem
      onClick={() => mutate()}
      label="Hard Reset"
      icon={<HardResetIcon />}
      disabled={!record.isObserving && record.IMEI !== KICKMAKER_DEVICE_IMEI}
    />
  );
};

export const RequestStatusAction = () => {
  const record = useRecordContext();

  const { mutate } = useNotifyAction(
    () =>
      dataProvider.sendConfigFlags(record!.id as string, {
        ReqStatus: true,
      }),
    { successMessage: "Status requested" }
  );

  if (record.majorHWVersion === 1) {
    return null;
  }

  return (
    <ActionMenuItem
      onClick={() => mutate()}
      label="Request Status"
      icon={<StatusIcon />}
      disabled={!record.isObserving && record.IMEI !== KICKMAKER_DEVICE_IMEI}
    />
  );
};

export const RequestRecoveryAction = () => {
  const record = useRecordContext();

  const { mutate } = useNotifyAction(
    () =>
      dataProvider.sendConfigFlags(record!.id as string, {
        ReqRecovery: true,
      }),
    { successMessage: "Recovery requested" }
  );

  if (record.majorHWVersion === 1) {
    return null;
  }

  return (
    <ActionMenuItem
      onClick={() => mutate()}
      label="Request Recovery"
      icon={<RecoveryIcon />}
      disabled={!record.isObserving && record.IMEI !== KICKMAKER_DEVICE_IMEI}
    />
  );
};

export const RequestDebugAction = () => {
  const record = useRecordContext();

  const { mutate } = useNotifyAction(
    () =>
      dataProvider.sendConfigFlags(record!.id as string, {
        ReqDebug: true,
      }),
    { successMessage: "Debug requested" }
  );

  if (record.majorHWVersion === 1) {
    return null;
  }

  return (
    <ActionMenuItem
      onClick={() => mutate()}
      label="Request Debug"
      icon={<DebugIcon />}
      disabled={!record.isObserving && record.IMEI !== KICKMAKER_DEVICE_IMEI}
    />
  );
};

export const RequestServerCaUpdateAction = () => {
  const record = useRecordContext();

  const { mutate } = useNotifyAction(
    () =>
      dataProvider.sendConfigFlags(record!.id as string, {
        ReqCertServ: true,
      }),
    { successMessage: "Server CA update requested" }
  );

  if (
    record.IMEI !== KICKMAKER_DEVICE_IMEI &&
    (record.majorHWVersion === 1 ||
      !satisfies(record.firmwareVersion, ">=2.12 <3.0.0"))
  ) {
    return null;
  }

  return (
    <ActionMenuItem
      onClick={() => mutate()}
      label="Request Server CA Update"
      icon={<SecurityIcon />}
      disabled={!record.isObserving && record.IMEI !== KICKMAKER_DEVICE_IMEI}
    />
  );
};

export const RequestDeviceCertificateUpdateAction = () => {
  const record = useRecordContext();

  const { mutate } = useNotifyAction(
    () =>
      dataProvider.sendConfigFlags(record!.id as string, {
        ReqCertDev: true,
      }),
    { successMessage: "Device certificate update requested" }
  );

  if (
    record.IMEI !== KICKMAKER_DEVICE_IMEI &&
    (record.majorHWVersion === 1 ||
      !satisfies(record.firmwareVersion, ">=2.12 <3.0.0"))
  ) {
    return null;
  }

  return (
    <ActionMenuItem
      onClick={() => mutate()}
      label="Request Device Certificate Update"
      icon={<SecurityIcon />}
      disabled={!record.isObserving && record.IMEI !== KICKMAKER_DEVICE_IMEI}
    />
  );
};

export const RequestCertificatesUpdateAction = () => {
  const record = useRecordContext();

  const { mutate } = useNotifyAction(
    () =>
      dataProvider.sendConfigFlags(record!.id as string, {
        ReqCertServ: true,
        ReqCertDev: true,
      }),
    { successMessage: "Certificates update requested" }
  );

  if (record.majorHWVersion === 1) {
    return null;
  }

  return (
    <ActionMenuItem
      onClick={() => mutate()}
      label="Request Certificates Update"
      icon={<SecurityIcon />}
      disabled={!record.isObserving}
    />
  );
};

export const RequestFOTA = () => {
  const record = useRecordContext();

  const { mutate } = useNotifyAction(
    () =>
      dataProvider.sendConfigFlags(record!.id as string, {
        ReqFota: true,
      }),
    { successMessage: "Firmware OTA requested" }
  );

  if (record.majorHWVersion === 1) {
    return null;
  }

  return (
    <Tooltip title="Request Firmware OTA (a request is automatically sent every hour if the target firmware version does not match the current firmware version)">
      <Button
        startIcon={<FOTAIcon fontSize="inherit" />}
        onClick={() => mutate()}
        disabled={!record.isObserving && record.IMEI !== KICKMAKER_DEVICE_IMEI}
        variant="outlined"
        size="small"
      >
        Request OTA now
      </Button>
    </Tooltip>
  );
};
