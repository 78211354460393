import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import { List, useListContext } from "react-admin";
import { TempoCalendar } from "./TempoCalendar";

export const ElectricityPriceList = () => {
  return (
    <List perPage={1000} sort={{ field: "datetime", order: "DESC" }}>
      <PriceChart />
    </List>
  );
};

const PriceChart = () => {
  const { data } = useListContext();

  const [chartOptions, setChartOptions] = useState(options);

  const updateSeries = (data: any[]) => {
    setChartOptions({
      series: [
        {
          name: "Price",
          data:
            data
              .reverse()
              .map(({ upcomingHourPrice, datetime }) => [
                new Date(datetime).getTime(),
                upcomingHourPrice,
              ]) || [],
          type: "area",
          color: "#7cb5ec",
          tooltip: {
            valueSuffix: " €/MWh",
          },
        },
      ],
    });
  };

  useEffect(() => {
    if (data) {
      updateSeries(data);
    }
  }, [data]);

  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      <TempoCalendar />
    </>
  );
};

const options: Highcharts.Options = {
  title: {
    text: "Electricity Price in France",
  },
  subtitle: {
    text: "source EPEX Spot",
  },
  xAxis: {
    type: "datetime",
    title: {
      text: "Time in France (CET/CEST)",
    },
  },
  yAxis: {
    title: {
      text: "€/MWh",
    },
  },
  time: {
    useUTC: false,
    timezone: "Europe/Paris",
  },
  tooltip: {
    xDateFormat: "%d/%m/%y %H:%M",
  },
  chart: {
    zooming: {
      type: "x",
    },
    events: {
      selection: (event) => {
        console.log("selection", event);
        // if (event.xAxis && event.xAxis[0]) {
        // }
        return undefined;
      },
    },
  },
  rangeSelector: {
    enabled: true,
    allButtonsEnabled: true,
    buttons: [
      {
        count: 1,
        type: "all",
        text: "All",
      },
      {
        count: 1,
        type: "week",
        text: "Week",
      },
      {
        count: 1,
        type: "day",
        text: "Day",
      },
    ],
    inputEnabled: false,
    selected: 2,
  },
  plotOptions: {
    area: {
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
        },
        stops: [
          [0, Highcharts.getOptions().colors![0] as any],
          [
            1,
            Highcharts.color(Highcharts.getOptions().colors![0])
              .setOpacity(0)
              .get("rgba"),
          ],
        ],
      },
      marker: {
        radius: 2,
      },
      lineWidth: 1,
      states: {
        hover: {
          lineWidth: 1,
        },
      },
      threshold: null,
    },
  },
};
