import { red } from "@mui/material/colors";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getScheduleSeries } from "../data/charts/schedule-series";
import type { Instruction } from "../data/charts/schedule-series";

interface ScheduleChartProps {
  weeklyInstructions: Instruction[];
}

export const ScheduleChart: React.FC<ScheduleChartProps> = ({
  weeklyInstructions,
}) => {
  const scheduleSeries = getScheduleSeries(
    weeklyInstructions,
    dayjs().startOf("week").add(1, "day").toDate(),
    dayjs().endOf("week").add(1, "day").toDate()
  );

  let schedule2Series: null | any[] = null;
  if (weeklyInstructions[0]?.setPoint2 !== undefined) {
    schedule2Series = getScheduleSeries(
      weeklyInstructions.map((instruction) => ({
        setPoint: instruction.setPoint2 ?? instruction.setPoint,
        hysteresis: instruction.hysteresis2 ?? instruction.hysteresis,
        time: instruction.time,
      })),
      dayjs().startOf("week").add(1, "day").toDate(),
      dayjs().endOf("week").add(1, "day").toDate()
    );
  }

  console.log(schedule2Series);

  const series = [
    {
      name: "Schedule",
      step: "left",
      type: "line",
      color: "#2196f3",
      data: scheduleSeries,
    },
  ];

  if (schedule2Series) {
    series.push({
      name: "Schedule 2",
      step: "left",
      type: "line",
      color: red["500"],
      data: schedule2Series,
    });
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        ...chartOptions,
        series,
      }}
    />
  );
};

const chartOptions: Highcharts.Options = {
  chart: {
    height: 200,
  },
  legend: {
    enabled: false,
  },
  title: {
    text: "",
  },
  tooltip: {
    xDateFormat: "%A %H:%M",
  },
  xAxis: {
    type: "datetime",
    dateTimeLabelFormats: {
      millisecond: "%A %H:%M:%S.%L",
      second: "%A %H:%M:%S",
      minute: "%A %H:%M",
      hour: "%A %H:%M",
      day: "%A",
      week: "%A",
      month: "%A %b '%y",
      year: "%A %Y",
    },
  },
  yAxis: [
    {
      title: {
        text: "",
      },
    },
  ],
  time: {
    useUTC: false,
  },
};
