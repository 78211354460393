import { LinearProgress } from "@mui/material";
import { get, groupBy, mapValues, orderBy } from "lodash";
import { useEffect } from "react";
import type { ReactNode } from "react";
import {
  ReferenceFieldView,
  ResourceContextProvider,
  useDataProvider,
  useListContext,
  useRecordContext,
} from "react-admin";
import { useQuery } from "react-query";

interface OptimizedReferenceOneFieldProps {
  reference: string;
  target: string;
  sortField?: string;
  sortOrder?: "asc" | "desc";
  label: string;
  sortable?: boolean;
  link?: string | false;
  children: ReactNode;
  fields: string[];
  filter?: any;
  noDataChildren?: ReactNode;
}

export const OptimizedReferenceOneField: React.FC<
  OptimizedReferenceOneFieldProps
> = ({
  reference,
  target,
  sortField = "id",
  sortOrder = "asc",
  children,
  fields,
  filter,
  noDataChildren,
}) => {
  const { data } = useListContext();
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const {
    data: references,
    refetch,
    error,
  } = useQuery(
    [
      reference,
      "referenceOneField",
      { sortField, sortOrder, target, fields, filter },
    ],
    () =>
      dataProvider
        .getList<any>(reference, {
          pagination: { perPage: 10_000, page: 1 },
          sort: { field: "id", order: "ASC" },
          filter: { ...filter, [target]: data?.map(({ id }) => id) },
          meta: { fields: [...fields, target, sortField] },
        })
        .then(({ data }) => {
          return mapValues(
            groupBy(data, (v: any) => get(v, target)),
            (v) => orderBy(v, sortField, sortOrder)[0]
          );
        }),
    { enabled: !!data }
  );

  useEffect(() => {
    refetch();
  }, [data, refetch]);

  if (!references || !record) {
    return <LinearProgress />;
  }
  const item = references[record.id];

  if (!item) {
    return noDataChildren ?? null;
  }

  return (
    <ResourceContextProvider value={item}>
      <ReferenceFieldView
        isLoading={false}
        isFetching={false}
        referenceRecord={item}
        resourceLinkPath={false}
        reference={reference}
        refetch={refetch as any}
        error={error}
      >
        {children}
      </ReferenceFieldView>
    </ResourceContextProvider>
  );
};

export const useOptimizedReferences = ({
  reference,
  target,
  fields,
  filter,
}: {
  reference: string;
  target: string;
  fields: string[];
  filter?: any;
}) => {
  const { data } = useListContext();
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const {
    data: references,
    refetch,
    error,
  } = useQuery(
    [
      target,
      "useOptimizedReferences",
      { target, fields },
      data.map(({ id }) => id).join("-"),
    ],
    () =>
      dataProvider
        .getList<any>(reference, {
          pagination: { perPage: 10_000, page: 1 },
          sort: { field: "id", order: "ASC" },
          filter: { ...filter, [target]: data?.map(({ id }) => id) },
          meta: { fields: [...fields, target] },
        })
        .then(({ data }) => groupBy(data, (v: any) => v[target])),
    { enabled: !!data }
  );

  return {
    data: (references && references[record.id]) || [],
    refetch,
    error,
  };
};

interface OptimizedReferenceOneFieldProps {
  reference: string;
  target: string;
  label: string;
  sortable?: boolean;
  filter?: any;
}

interface OptimizedReferencesFieldProps {
  reference: string;
  target: string;
  label: string;
  sortable?: boolean;
  fields: string[];
  filter?: any;
  render: (records: any[], opts: { refetch: any; error: any }) => ReactNode;
}

export const OptimizedReferencesField: React.FC<
  OptimizedReferencesFieldProps
> = ({ reference, target, render, fields, filter }) => {
  const { data, refetch, error } = useOptimizedReferences({
    reference,
    target,
    fields,
    filter,
  });
  const record = useRecordContext();

  if (!data || !record) {
    return <LinearProgress />;
  }

  return <>{render(data, { refetch, error })}</>;
};
