import axios, { AxiosHeaders } from "axios";
import type { AxiosError } from "axios";
import dayjs from "dayjs";
import type { DataProvider } from "react-admin";
import { API_BASE_URL, API_SAVINGS_BASE_URL } from "../config";
import type { DemandResponse } from "../resources/demand-response/types";
import { getToken, getUnverifiedTokenPayload } from "./auth";

export enum YearlyNormalization {
  ELAX = "elax",
  ADEME = "ademe",
  NO = "no",
}

export const axiosClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Accept: "application/json",
  },
});
export const axiosClientSavingsApi = axios.create({
  baseURL: API_SAVINGS_BASE_URL,
  headers: {
    Accept: "application/json",
  },
});

[axiosClient, axiosClientSavingsApi].forEach((client) => {
  client.interceptors.request.use(
    function (config) {
      const token = getToken();
      if (token) {
        config.headers = new AxiosHeaders({
          ...config.headers,
          Authorization: token && `Bearer ${token}`,
        });
      }
      return config;
    },
    null,
    { synchronous: true }
  );

  client.interceptors.response.use(
    (response) => response,
    (error: AxiosError<any>) => {
      error.message = error.response?.data?.message || error.message;
      return Promise.reject(error);
    }
  );
});

export const getBlob = async (route: string, mime: string) => {
  const res = await axiosClient
    .get(route, {
      responseType: "arraybuffer",
      headers: {
        Accept: mime,
      },
    })
    .catch((error) => {
      throw new Error(
        error.response?.data
          ? JSON.parse(new TextDecoder().decode(error.response.data)).message
          : error.message
      );
    });

  return new Blob([res.data], { type: mime });
};

export const downloadFile = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.click();
};

const getList: DataProvider["getList"] = async (resource, params) => {
  const { page, perPage } = params.pagination;
  const { field, order } = params.sort;

  const rangeStart = (page - 1) * perPage;
  const rangeEnd = page * perPage - 1;

  const { data, headers } = await axiosClient.get(`/${resource}`, {
    params: {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([rangeStart, rangeEnd]),
      filter: JSON.stringify(params.filter),
      ...params.meta,
    },
    // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
    headers: new AxiosHeaders({
      Range: `${resource}=${rangeStart}-${rangeEnd}`,
    }),
  });

  return {
    data,
    total: Number.parseInt(headers["content-range"].split("/").pop(), 10),
  };
};

const getManyReference: DataProvider["getManyReference"] = async (
  resource,
  params
) =>
  getList(resource, {
    ...params,
    filter: { ...params.filter, [params.target]: params.id },
  });

const getOne: DataProvider["getOne"] = async (resource, params) =>
  axiosClient.get(`/${resource}/${params.id}`);

const getMany: DataProvider["getMany"] = async (resource, params) => {
  return axiosClient.get(`/${resource}`, {
    params: {
      filter: JSON.stringify({ id: params.ids }),
      ...params.meta,
    },
  });
};

const update: DataProvider["update"] = async (resource, params) =>
  axiosClient.put(`/${resource}/${params.id}`, params.data);

const updateMany: DataProvider["updateMany"] = async (resource, params) =>
  Promise.all(
    params.ids.map((id) => axiosClient.put(`/${resource}/${id}`, params.data))
  ).then((responses) => ({ data: responses.map(({ data }) => data.id) }));

const create: DataProvider["create"] = (resource, params) =>
  axiosClient.post(`/${resource}`, params.data);

const deleteOne: DataProvider["delete"] = (resource, params) =>
  axiosClient.delete(`/${resource}/${params.id}`, {
    headers: new AxiosHeaders({
      "Content-Type": "text/plain",
    }),
  });

const deleteMany: DataProvider["deleteMany"] = (resource, params) =>
  Promise.all(
    params.ids.map((id) =>
      axiosClient.delete(`/${resource}/${id}`, {
        headers: new AxiosHeaders({
          "Content-Type": "text/plain",
        }),
      })
    )
  ).then((responses) => ({
    data: responses.map(({ data }) => data.id),
  }));

export const dataProvider = {
  getList,
  getOne,
  getMany,
  getManyReference,
  update,
  updateMany,
  create,
  delete: deleteOne,
  deleteMany,
  bulkUpdate: (resource: string, filter: any, body: any) => {
    return axiosClient.put(`/${resource}`, body, {
      params: { filter: JSON.stringify(filter) },
    });
  },
  setPDLs: (
    entity: string,
    action: "ADD" | "SET" | "REMOVE",
    pdls: string[]
  ) => {
    return axiosClient.put(`/demand-response-entities/${entity}/pdls`, {
      action,
      pdls,
    });
  },
  setPassword: (password: string) =>
    axiosClient.put(`${API_BASE_URL.replace("/admin", "")}/user/password`, {
      password,
    }),
  forgotPassword: (email: string) =>
    axiosClient.post(
      `${API_BASE_URL.replace("/admin", "")}/user/reset-password`,
      {
        email,
      }
    ),
  search: (q: string) =>
    axiosClient
      .get(`/search?q=${encodeURIComponent(q)}`)
      .then((result) => result.data),
  getSavingsSummary: async (deviceId: string) => {
    const { data } = await axiosClient.get<{
      kWhPriceInEuro: number;
      reference: {
        dailyConsumptionInKWh: string;
        rawDailyConsumptionInKWh: string;
        annualisedDailyConsumptionInKWh: string;
        absenceCount: number;
        inconsistentCount: number;
        dayCount: number;
        averageDailyDataCoverageInHours: number;
        averageDailyInconsistentDataInMinutes: number;
        slot: null | { start: Date; end: Date | null };
        processableDayCount: number;
      };
      pilot?: {
        dailyConsumptionInKWh: string;
        rawDailyConsumptionInKWh: string;
        annualisedDailyConsumptionInKWh: string;
        absenceCount: number;
        inconsistentCount: number;
        dayCount: number;
        averageDailyDataCoverageInHours: number;
        averageDailyInconsistentDataInMinutes: number;
        slot: null | { start: Date; end: null };
        processableDayCount: number;
      };
      savings?: {
        ratio: string;
        annual: string;
      };
    }>(`/devices/${deviceId}/savings`);
    return data;
  },
  isDeviceObserving: (IMEI: number) =>
    axiosClient.get(`/devices/${IMEI}/observe`).then((result) => result.data),
  copyDevice: (
    deviceId: string,
    body: { deviceName: string; toCopy: string[]; from: Date; to: Date }
  ) => axiosClient.put(`/devices/${deviceId}/copy`, body),
  importProject: (projectId: string) => {
    return axiosClient
      .put(`/projects/${projectId}/import`)
      .then((response) => response.data);
  },
  sendConfigFlags: (
    deviceId: string,
    body: Partial<{
      ReqStatus: boolean;
      ReqHardReset: boolean;
      ReqFota: boolean;
      ReqTest: boolean;
      ReqRecovery: boolean;
      ReqDebug: boolean;
      ReqCertServ: boolean;
      ReqCertDev: boolean;
    }>
  ) => axiosClient.post(`/devices/${deviceId}/config-flags`, body),
  getEnergy: async (deviceId: string, startDate: Date, endDate: Date) => {
    const { data } = await axiosClient.get(`/energies/derivative`, {
      params: {
        deviceId,
        startDate,
        endDate,
      },
    });
    return data;
  },
  syncKizeo: (interventionId: string) =>
    axiosClient
      .put(`/interventions/${interventionId}/kizeo-sync`)
      .then((response) => response.data),
  syncProjects: () =>
    axiosClient.put(`/projects/sync`).then((response) => response.data),
  resetPassword: () =>
    axiosClient.put(`/users/reset-password`).then((response) => response.data),
  syncAllKizeo: () =>
    axiosClient.put(`/trigger-kizeo-job`).then((response) => response.data),
  saveUserConfig: (values: Record<string, any>) => {
    const payload = getUnverifiedTokenPayload();
    return axiosClient
      .put(`/users/${payload._id}`, { backOfficeConfig: values })
      .then((response) => response.data);
  },
  getSmartSchedule: ({
    deviceId,
    mode,
    parameters,
  }: {
    deviceId: string;
    mode: string;
    parameters: any;
  }) =>
    axiosClient
      .get(
        `/smart-schedule?deviceId=${deviceId}&mode=${mode}&parameters=${JSON.stringify(
          parameters
        )}`
      )
      .then((response) => response.data),
  applyIntervention: async (id: string) =>
    axiosClient.put(`/interventions/${id}/apply`),
  invite: async (
    email: string,
    role: string,
    organizationId?: string,
    organizationRole?: string,
    sendEmail?: boolean
  ) => {
    const { data } = await axiosClient.post<{ token: string }>(
      `${API_BASE_URL.replace("/admin", "")}/user/invite`,
      {
        email,
        role,
        organizationId,
        organizationRole,
        sendEmail,
      }
    );
    return data.token;
  },
  addSftpUser: async (organizationId: string, username: string) => {
    const { data } = await axiosClient.post<{ password: string }>(
      `/organization/${organizationId}/sftp-user`,
      {
        username,
      }
    );
    return data.password;
  },
  removeSftpUser: async (organizationId: string, username: string) => {
    await axiosClient.delete<{ password: string }>(
      `/organization/${organizationId}/sftp-user/${username}`
    );
  },
  exportData: async (resource: "end-users" | "devices", filter: any) => {
    const { data } = await axiosClient.get(`/${resource}/export`, {
      params: {
        filter: JSON.stringify(filter),
      },
    });
    return data;
  },
  exportFrames: async (filter: any, startDate: string, endDate: string) => {
    const { data } = await axiosClient.get(`/frames/export`, {
      params: {
        filter: JSON.stringify(filter),
        startDate: dayjs(startDate).format("YYYY-MM-DDTHH:mm:ss"),
        endDate: dayjs(endDate).format("YYYY-MM-DDTHH:mm:ss"),
      },
    });
    return data;
  },
  generateConsumptionReport: async (filter: any) => {
    const { data } = await axiosClient.get(`/consumption-report`, {
      params: {
        filter: JSON.stringify(filter),
      },
    });
    return data;
  },
  exportInterventions: (id: string) =>
    axiosClient
      .get(`/projects/${id}/interventions/export`)
      .then((response) => response.data),
  exportIncidents: (filter: object) =>
    axiosClient
      .get(`/incidents/export`, {
        params: {
          filter: JSON.stringify(filter),
        },
      })
      .then((response) => response.data),
  getDemandResponses: (deviceId: string, startDate: Date, endDate: Date) =>
    axiosClient
      .get<DemandResponse[]>(`/devices/${deviceId}/demand-responses`, {
        params: { startDate, endDate },
      })
      .then((response) => response.data),
  installDevice: (
    deviceId: string,
    housingId: string,
    date: string,
    comment: string
  ) =>
    axiosClient
      .put(`/devices/${deviceId}/install`, { date, comment, housingId })
      .then((response) => response.data),
  uninstallDevice: (
    id: string,
    date: string,
    comment: string,
    removalResponsible: string
  ) =>
    axiosClient
      .put(`/devices/${id}/uninstall`, { date, comment, removalResponsible })
      .then((response) => response.data),
  declareMoving: (housingId: string) =>
    axiosClient
      .put(`/housings/${housingId}/moving`)
      .then((response) => response.data),
  declareMovings: (fileId: string) =>
    axiosClient
      .put(`/housings/movings`, { fileId })
      .then((response) => response.data),
  replaceDevice: ({
    replacedDeviceId,
    deviceId,
    date,
    comment,
  }: {
    replacedDeviceId: string;
    deviceId: string;
    date: string;
    comment: string;
  }) =>
    axiosClient
      .put(`/devices/${replacedDeviceId}/replace`, { deviceId, date, comment })
      .then((response) => response.data),
  exportResidentAppAnalytics: () =>
    axiosClient.get(`/analytics/export`).then((response) => response.data),
  getDevicesConsumption: (filter: any) =>
    axiosClientSavingsApi
      .get<{
        data: {
          consumptionProgression: {
            timestamp: number;
            measuredConsumptionInKWh: number;
            estimatedNonPilotedConsumptionInKWh: number;
            deviceCount: number;
            pilotedDeviceCount: number;
          }[];
          averagePilotConsumption?: number;
          averageEstimatedNonPilotedConsumption?: number;
        };
        lastUpdateDate: string;
      }>(`/devices/consumption-v2`, {
        params: { filter: JSON.stringify(filter) },
      })
      .then((response) => response.data),
  getAnnualSavings: (filter: any, yearlyNormalized?: YearlyNormalization) =>
    axiosClientSavingsApi
      .get<{
        data: { id: string; annualSavingsInEuro: number }[];
        lastUpdateDate: string;
      }>(`/devices/annual-savings`, {
        params: { filter: JSON.stringify(filter), yearlyNormalized },
      })
      .then((response) => response.data),
  getDevicesContractType: (filter: any) =>
    axiosClient
      .get<{
        data: Record<string, number>;
        lastUpdateDate: string;
      }>(`/devices/contract-type`, {
        params: { filter: JSON.stringify(filter) },
      })
      .then((response) => response.data),
  getDevicesMonthlySavings: (
    filter: any,
    yearlyNormalized?: YearlyNormalization
  ) =>
    axiosClientSavingsApi
      .get<{
        data: {
          key: string;
          savingsInEuros: number;
          savingsInCO2: number;
          deviceCount: number;
        }[];
        lastUpdateDate: string;
      }>(`/devices/savings/monthly`, {
        params: { filter: JSON.stringify(filter), yearlyNormalized },
      })
      .then((response) => response.data),
  getDevicesSavingsStats: (
    filter: any,
    granularity: "monthly" | "weekly" | "daily",
    savings: ("kwh" | "euros")[],
    aggregationType: "avg" | "sum"
  ) =>
    axiosClientSavingsApi
      .get<{
        data: {
          key: string;
          timestamp: number;
          device_count?: number;
          savings_kwh_avg?: number;
          savings_euros_avg?: number;
        }[];
        lastUpdateDate: string;
      }>(`/devices/savings/stats`, {
        params: {
          filter: JSON.stringify(filter),
          granularity,
          stats: { savings },
          aggregation: aggregationType,
        },
      })
      .then((response) => response.data),
  getDevicesSavingProgression: (
    filter: any,
    yearlyNormalized?: YearlyNormalization
  ) =>
    axiosClientSavingsApi
      .get<{
        data: {
          timestamp: number;
          averageAnnualSavingsInEuro: number;
          decilesAnnualSavingsInEuro: number[];
          averageSavings: number;
          decilesSavings: number[];
          averageReferenceConsumptionInWh: number;
          averagePilotConsumptionInWh: number;
        }[];
        lastUpdateDate: string;
      }>(`/devices/savings/progression`, {
        params: { filter: JSON.stringify(filter), yearlyNormalized },
      })
      .then((response) => response.data),
  getDevicesRawConsumption: (filter: any) =>
    axiosClientSavingsApi
      .get<{
        consumption: [any];
      }>(`/devices/raw-consumption`, {
        params: { filter: JSON.stringify(filter) },
      })
      .then((response) => response.data),
  getDeviceStats: (filter: any, yearlyNormalized?: YearlyNormalization) =>
    axiosClient
      .get<{
        data: { deviceId: string; savingsState: string }[];
        lastUpdateDate: string;
      }>(`/devices/savings-state`, {
        params: { filter: JSON.stringify(filter), yearlyNormalized },
      })
      .then((response) => response.data),
  getDevicesState: (filter: any) =>
    axiosClient
      .get<{
        data: Record<string, number>;
        lastUpdateDate: string;
      }>(`/devices/state`, {
        params: { filter: JSON.stringify(filter) },
      })
      .then((response) => response.data),
  getDevicesHealthRisks: (filter: any) =>
    axiosClient
      .get<{
        data: {
          devicesCount: number;
          observation: {
            burns: number;
            legionellosis: number;
            legionellosisCountByCause: {
              limescale: number;
              processing_incident: number;
              thermostat: number;
            };
          };
          pilot: {
            burns: number;
            legionellosis: number;
            legionellosisCountByCause: {
              limescale: number;
              processing_incident: number;
              thermostat: number;
            };
          };
        };
        lastUpdateDate: string;
      }>(`/devices/health-risks`, {
        params: { filter: JSON.stringify(filter) },
      })
      .then((response) => response.data),
  getInterventionAnalytics: () =>
    axiosClient
      .get<{
        data: { installations: any[]; usedDevicesCount: number };
        lastUpdateDate: string;
      }>(`/interventions/analytics`)
      .then((response) => response.data),
  getInterventionCount: (
    startDate: Date | undefined,
    granularity: "year" | "month" | "week" | "day",
    projectId: string | undefined,
    dealId: string | undefined
  ) =>
    axiosClient
      .get<
        {
          key: string;
          values: Record<string, number>;
          startDate: string;
          endDate: string;
        }[]
      >(`/interventions/count`, {
        params: {
          startDate,
          granularity,
          ...(projectId ? { projectId } : { dealId }),
        },
      })
      .then((response) => response.data),
  getHeatersState: (filter: any) =>
    axiosClient
      .get<{
        data: {
          deviceId: string;
          state: string;
          power: number;
          volume: number;
        }[];
        lastUpdateDate: string;
      }>(`/heaters/state`, {
        params: { filter: JSON.stringify(filter) },
      })
      .then((response) => response.data),
  getDemandResponseEntityConsumption: (
    id: string,
    params?: { startDate?: string; endDate?: string }
  ) =>
    axiosClient
      .get<{
        lastUpdateDate: string;
        data: {
          day: string;
          data: { timestamp: number; value: number }[];
        }[];
      }>(`/demand-response-entities/${id}/consumption-profile`, {
        params,
      })
      .then((response) => response.data),
  getDeviceHistory: (deviceId: string) =>
    axiosClient
      .get<
        {
          date: string;
          type: string;
          title?: string;
          content?: string;
          url?: string;
        }[]
      >(`/devices/${deviceId}/history`)
      .then((response) => response.data),

  getInstallationAppointmentCommunications: (projectId: string) =>
    axiosClient
      .get<{ emails: string[]; phoneNumbers: string[] }>(
        `/projects/${projectId}/communications-installation-appointment`
      )
      .then((response) => response.data),

  sendInstallationAppointmentCommunications: (projectId: string) =>
    axiosClient
      .post<void>(
        `/projects/${projectId}/communications-installation-appointment`
      )
      .then((response) => response.data),
};
