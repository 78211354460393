import { mean } from "lodash";
import { useRecordContext } from "react-admin";
import type { Device } from "../types";

export const SavingsField: React.FC<{ label?: string }> = () => {
  const device = useRecordContext<Device>();

  if (
    !device ||
    !device.referenceEnergy ||
    !device.energy_ontimer_last_seven_days
  ) {
    return <>-</>;
  }

  const values = device.energy_ontimer_last_seven_days.filter((v) => !isNaN(v));

  if (values.length === 0) {
    return <>-</>;
  }

  return <>{((device.referenceEnergy - mean(values)) / 1000).toFixed(3)}</>;
};
