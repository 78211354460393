import { Grid, Typography } from "@mui/material";
import {
  ArrayField,
  Datagrid,
  DateField,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  ShowButton,
  TabbedShowLayout,
  TextField,
} from "react-admin";
import { InlineLabel } from "../../layout/InlineLabel";
import {
  ActionsMenu,
  DeleteAction,
  EditAction,
} from "../../layout/actions-menu";
import { communicationFields } from "../communications/fields";
import { DataTab } from "../devices/show/tabs/data";
import { HousingLastProjectField } from "../housing/fields/HousingLastProjectField";
import { InterventionStatusField } from "../intervention/fields/status";
import { InterventionTypeField } from "../intervention/fields/type";
import { ContactSourceField } from "./fields/ContactSourceField";
import { ContactValidationStatusField } from "./fields/ContactValidationStatusField";
import { ContactsField } from "./fields/ContactsField";
import { NameField } from "./fields/NameField";
import { ZendeskTicketStatusField } from "./fields/ZendeskTicketStatusField";
import { ZendeskTicketUrlField } from "./fields/ZendeskTicketUrlField";
import { ZendeskUserUrlField } from "./fields/ZendeskUserUrlField";

const ContactHistory = () => {
  return (
    <ArrayField source="validationHistory">
      <Datagrid
        bulkActionButtons={false}
        sx={{
          borderLeft: "1px dotted #0f5ef6",
        }}
      >
        <DateField label="Date" source="date" showTime />
        <ContactValidationStatusField label="Validation Status" />
        <ContactSourceField label="Source" />
      </Datagrid>
    </ArrayField>
  );
};

export const EndUserShow = () => {
  return (
    <Show actions={<EndUserActions />}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Data">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h6">
                <NameField />
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <InlineLabel label="Imported At">
                <DateField source="createdAt" showTime />
              </InlineLabel>
              <InlineLabel label="Emails">
                <ContactsField type="emails" emptyText="-" />
              </InlineLabel>
              <InlineLabel label="Phone numbers">
                <ContactsField type="phoneNumbers" emptyText="-" />
              </InlineLabel>
              <InlineLabel label="Notes">
                <TextField source="notes" emptyText="-" />
              </InlineLabel>
              <InlineLabel label="Energy saving sensitivity /10">
                <NumberField source="energy_saving_sensitive" />
              </InlineLabel>
              <InlineLabel label="Persona">
                <ReferenceField
                  reference="device-config-personas"
                  source="deviceConfigPersona"
                />
              </InlineLabel>
              <InlineLabel label="NPS">
                <TextField source="nps" emptyText="-" />
              </InlineLabel>
              <InlineLabel label="Consent to terms of service">
                <DateField source="consent_to_terms_of_service" emptyText="-" />
              </InlineLabel>
              <InlineLabel label="Consent to device data collection">
                <DateField
                  source="consent_to_device_data_collection"
                  emptyText="-"
                />
              </InlineLabel>
              <InlineLabel label="Consent to demand response">
                <DateField source="consent_to_demand_response" emptyText="-" />
              </InlineLabel>
              <InlineLabel label="Consent to electricity data collection">
                <DateField
                  source="consent_to_electricity_data_collection"
                  emptyText="-"
                />
              </InlineLabel>
            </Grid>
            <Grid item xs={12} md={6}>
              <ReferenceField
                reference="housings"
                source="housingId"
                link={false}
                emptyText={
                  '⚠️ No current housing. This end-user will be deleted after one year. History is available in the "Housing history" tab'
                }
              >
                <Typography variant="h6">
                  Housing <ShowButton />
                </Typography>
                <InlineLabel label="Unit ID">
                  <TextField source="unitId" emptyText="-" />
                </InlineLabel>
                <InlineLabel label="Address">
                  <TextField source="address" emptyText="-" />
                </InlineLabel>
                <InlineLabel label="Last Project">
                  <HousingLastProjectField label="Last project" />
                </InlineLabel>
              </ReferenceField>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">
                Zendesk tickets
                <ZendeskUserUrlField />
              </Typography>
              <ReferenceField reference="end-user-zendesk-tickets" source="id">
                <ArrayField source="zendeskTickets">
                  <Datagrid
                    bulkActionButtons={false}
                    expand={
                      <TextField
                        label="Description"
                        source="description"
                        display="block"
                        sx={{ whiteSpace: "pre-wrap" }}
                      />
                    }
                  >
                    <DateField label="Date" source="createdAt" showTime />
                    <ZendeskTicketStatusField label="Status" />
                    <TextField label="Type de demandeur" source="askingType" />
                    <TextField
                      label="Analyse technique de la cause du problème"
                      source="problemCause"
                    />
                    <ZendeskTicketUrlField label="Open in Zendesk" />
                  </Datagrid>
                </ArrayField>
              </ReferenceField>
            </Grid>
            <ReferenceField
              reference="housings"
              source="housingId"
              link={false}
            >
              <Grid item xs={12}>
                <ReferenceManyField
                  reference="interventions"
                  target="housingId"
                  sort={{ field: "datetime", order: "ASC" }}
                  fullWidth
                >
                  <Typography variant="h6">
                    Current housing interventions
                  </Typography>
                  <Datagrid bulkActionButtons={false}>
                    <InterventionTypeField />
                    <InterventionStatusField />
                    <DateField label="Date" source="datetime" showTime />
                    <ShowButton />
                  </Datagrid>
                </ReferenceManyField>
              </Grid>
              <Grid item xs={12}>
                <ReferenceManyField
                  reference="devices"
                  target="housingId"
                  sort={{ field: "datetime", order: "ASC" }}
                  fullWidth
                >
                  <Typography variant="h6">Current housing Devices</Typography>
                  <Datagrid bulkActionButtons={false} expand={<DataTab />}>
                    <TextField source="name" />
                    <ShowButton />
                  </Datagrid>
                </ReferenceManyField>
              </Grid>
            </ReferenceField>
            <Grid item xs={12}>
              <Typography variant="h6">Contacts</Typography>
              <Typography variant="subtitle1" mt={1}>
                Emails
              </Typography>
              <ArrayField source="contacts.emails.all">
                <Datagrid
                  bulkActionButtons={false}
                  expand={<ContactHistory />}
                  size="small"
                >
                  <TextField source="emailAddress" label="Email" />
                  <ContactSourceField label="Source" />
                  <DateField source="createdAt" showTime label="Date" />
                </Datagrid>
              </ArrayField>
              <Typography variant="subtitle1" mt={2}>
                Phone Numbers
              </Typography>
              <ArrayField source="contacts.phoneNumbers.all">
                <Datagrid
                  bulkActionButtons={false}
                  expand={<ContactHistory />}
                  size="small"
                >
                  <TextField source="number" label="Number" />
                  <ContactSourceField label="Source" />
                  <DateField source="createdAt" showTime label="Date" />
                </Datagrid>
              </ArrayField>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Communications</Typography>
              <ReferenceManyField
                reference="communications"
                target="context.endUserId"
                pagination={<Pagination />}
              >
                <Datagrid bulkActionButtons={false}>
                  {communicationFields.date}
                  {communicationFields.topic}
                  {communicationFields.medium}
                  {communicationFields.status}
                  {communicationFields.project}
                  {communicationFields.housing}
                  {communicationFields.externalId}
                </Datagrid>
              </ReferenceManyField>
            </Grid>
          </Grid>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Housing history" path="housing-history">
          <ArrayField source="housingHistory" emptyText="-">
            <Datagrid bulkActionButtons={false}>
              <TextField label="Type" />
              <DateField source="date" showTime />
              <ReferenceField
                source="housingId"
                reference="housings"
                link="show"
              >
                <TextField source="unitId" />
              </ReferenceField>
            </Datagrid>
          </ArrayField>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="persona history" path="persona-history">
          <ReferenceManyField
            reference="end-users-persona-change-history"
            target="endUserId"
            label={false}
            sort={{ field: "createdAt", order: "DESC" }}
            pagination={<Pagination />}
          >
            <Datagrid bulkActionButtons={false}>
              <DateField label="Date" source="createdAt" showTime />
              <ReferenceField
                label="Persona"
                reference="device-config-personas"
                source="deviceConfigPersonaId"
                link="show"
                emptyText="None"
              />
              <TextField source="changedVia" />
              <ReferenceField
                label="Changed by"
                reference="users"
                source="changedBy"
                link="show"
              />
            </Datagrid>
          </ReferenceManyField>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const EndUserActions = () => (
  <ActionsMenu>
    <EditAction />
    <DeleteAction />
  </ActionsMenu>
);
