import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import { Stack } from "@mui/material";
import { useRecordContext } from "react-admin";
import { useQuery } from "react-query";
import { RateBox } from "../../../display/RateBox";
import { axiosClient } from "../../../providers/data";
import type { Deal } from "../../deal/type";
import type { Project } from "../../project/type";

export default function DeploymentSummary({
  entity,
}: {
  entity: "deal" | "project";
}) {
  const record = useRecordContext<Deal | Project>();

  const { data } = useQuery([entity, record.id, "deployment-summary"], () =>
    axiosClient
      .get(`/${entity}s/${record.id}/deployment-summary`)
      .then((res) => res.data)
  );

  return (
    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
      <RateBox
        icon={<PhoneOutlinedIcon />}
        title="Résidents contactés"
        value={data?.endUsersContactedCount}
        total={data?.housingsCount}
        tooltip="Nombre de résidents contactés selon la sheet 'Prise de RDV' / Nombre total de logements associés au projet"
      />
      <RateBox
        icon={<BuildOutlinedIcon />}
        title="Taux de pénétration"
        value={data?.penetrationRate}
      />
      <RateBox
        icon={<ReceiptOutlinedIcon />}
        title="Taux de facturation"
        value={data?.billingRate}
      />
    </Stack>
  );
}
