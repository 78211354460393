import { FunctionField } from "react-admin";
import type { Identifier } from "react-admin";
import { OptimizedReferenceOneField } from "../../../fields/OptimizedReferenceOneField";
import { CommunicationChip } from "../../communications/components/CommunicationChip";
import type { Communication } from "../../communications/type";
import { ResidentCommunicationTopic } from "../../communications/type";

export const DeploymentCommunicationsSentField = ({
  label,
  projectId,
  communicationType,
}: {
  label?: string;
  projectId: Identifier;
  communicationType: "letter" | "email";
}) => {
  return (
    <OptimizedReferenceOneField
      reference="communications"
      target="context.housingId"
      label="Deployment letter sent"
      sortable={false}
      fields={["id", "status", "statusDetails", "date"]}
      filter={{
        topic:
          communicationType === "letter"
            ? ResidentCommunicationTopic.DEPLOYMENT_LETTER
            : ResidentCommunicationTopic.INSTALLATION_APPOINTMENT,
        "context.projectId": projectId,
      }}
      noDataChildren={
        <CommunicationChip
          communication={null}
          label={communicationType === "letter" ? "Letter" : "Email/SMS"}
        />
      }
    >
      <FunctionField<Communication>
        label={label}
        render={(record) => (
          <CommunicationChip
            communication={record}
            label={communicationType === "letter" ? "Letter" : "Email/SMS"}
          />
        )}
      />
    </OptimizedReferenceOneField>
  );
};
