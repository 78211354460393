import { Grid, Typography } from "@mui/material";
import {
  FunctionField,
  ReferenceManyCount,
  useRecordContext,
} from "react-admin";
import { Link } from "react-router-dom";
import { InterventionsFlow } from "../../../../../layout/dashboard/widgets/InterventionsFlow";
import { DeploymentHousingList } from "../../../../housing/renderers/DeploymentHousingList";
import {
  InterventionStatus,
  InterventionType,
} from "../../../../intervention/types";
import { DeploymentProgress } from "../../../fields/DeploymentProgress";

export const ProjectDeployment = () => {
  const project = useRecordContext();

  return (
    <>
      <DeploymentProgress />
      <InterventionsFlow projectId={project.id as string} />
      <Grid container spacing={2} paddingTop={2}>
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <FunctionField
            render={(record: any) => (
              <Typography>
                {record.notes}
                {record.notes ? " - " : ""}
                <Link
                  to={`/interventions?filter=${JSON.stringify({
                    projectId: [record.id],
                    type: InterventionType.INSTALLATION,
                    status: InterventionStatus.SUCCESS,
                  })}`}
                >
                  <ReferenceManyCount
                    reference="interventions"
                    target="projectId"
                    filter={{
                      type: InterventionType.INSTALLATION,
                      status: InterventionStatus.SUCCESS,
                    }}
                  />{" "}
                  installations réussies{" / "}
                </Link>{" "}
                <Link to={`/devices?filter={"projectId":"${record.id}"}`}>
                  <ReferenceManyCount reference="devices" target="projectId" />{" "}
                  devices (à date)
                </Link>{" "}
                /{" "}
                <Link to={`/housings?filter={"projectId":"${record.id}"}`}>
                  <ReferenceManyCount reference="housings" target="projectId" />{" "}
                  housings
                </Link>{" "}
                /{" "}
                <Link to={`/interventions?filter={"projectId":"${record.id}"}`}>
                  <ReferenceManyCount
                    reference="interventions"
                    target="projectId"
                  />{" "}
                  interventions
                </Link>
              </Typography>
            )}
          />
        </Grid>
        <DeploymentHousingList target="projectId" />
      </Grid>
    </>
  );
};
