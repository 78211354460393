import { Grid } from "@mui/material";
import {
  ArrayInput,
  BooleanInput,
  DateInput,
  Edit,
  Link,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
} from "react-admin";
import { validationStatusLabels } from "./fields/ContactValidationStatusField";

export const EndUserEditionForm = () => {
  return (
    <SimpleForm>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <ArrayInput
            source="contacts.emails.validated"
            label="Emails"
            fullWidth
          >
            <SimpleFormIterator fullWidth>
              <TextInput type="email" source="" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
        <Grid item sm={6}>
          <ArrayInput
            source="contacts.phoneNumbers.validated"
            type="tel"
            label="Phone Numbers"
            fullWidth
          >
            <SimpleFormIterator fullWidth>
              <TextInput source="" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
        <Grid item sm={8}>
          <SelectInput
            source="validationStatus"
            fullWidth
            label="Validation Status"
            helperText="Status of the contact validation for any *new* emails or phone numbers above"
            defaultValue="backOffice:otherUnreliable"
            validate={required()}
            choices={[
              {
                id: "backOffice:givenByUser",
                name: validationStatusLabels["backOffice:givenByUser"],
              },
              {
                id: "backOffice:otherReliable",
                name: validationStatusLabels["backOffice:otherReliable"],
              },
              {
                id: "backOffice:otherUnreliable",
                name: validationStatusLabels["backOffice:otherUnreliable"],
              },
            ]}
          />
        </Grid>
        <Grid item sm={4}>
          <BooleanInput
            source="validationStatusApplyToAll"
            fullWidth
            label="Apply validation status to all"
            helperText="Apply the validation status to all emails and phone numbers above"
          />
        </Grid>
        <Grid item sm={4}>
          <SelectInput
            source="title1"
            fullWidth
            sx={{ marginTop: 0 }}
            choices={["M", "MME"].map((title) => ({ id: title, name: title }))}
          />
        </Grid>
        <Grid item sm={4}>
          <TextInput source="firstName1" fullWidth />
        </Grid>
        <Grid item sm={4}>
          <TextInput source="lastName1" fullWidth />
        </Grid>
        <Grid item sm={4}>
          <SelectInput
            source="title2"
            fullWidth
            sx={{ marginTop: 0 }}
            choices={["M", "MME"].map((title) => ({ id: title, name: title }))}
          />
        </Grid>
        <Grid item sm={4}>
          <TextInput source="firstName2" fullWidth />
        </Grid>
        <Grid item sm={4}>
          <TextInput source="lastName2" fullWidth />
        </Grid>
        <Grid item sm={12}>
          <ReferenceInput
            reference="device-config-personas"
            source="deviceConfigPersona"
            fullWidth
          >
            <SelectInput
              fullWidth
              helperText={
                <Link to="/device-config-personas/create">
                  Create new persona
                </Link>
              }
            />
          </ReferenceInput>
        </Grid>
        <Grid item sm={12}>
          <TextInput source="notes" fullWidth multiline minRows={4} />
        </Grid>
        <Grid item sm={12}>
          <DateInput source="consent_to_demand_response" />
        </Grid>
        <Grid item sm={6}>
          <BooleanInput source="optin_email" fullWidth />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export const EndUserEdit = () => (
  <Edit redirect="show" mutationMode="pessimistic">
    <EndUserEditionForm />
  </Edit>
);
