import { Autocomplete, TextField } from "@mui/material";
import { useGetList } from "react-admin";
import type { AutocompleteInputProps } from "react-admin";
import { useURLFilter } from "../../../filters/useURLFilter";

export const SelectProject: React.FC<AutocompleteInputProps> = (props) => {
  const { data } = useGetList("projects", {
    pagination: { perPage: 1000, page: 1 },
  });
  const [value, setValue] = useURLFilter(props.source ?? "projectId");

  const selectedOption = value
    ? data?.filter((o) => value.includes(o.id))
    : undefined;

  return (
    <Autocomplete
      size="small"
      sx={{ minWidth: 200 }}
      value={selectedOption}
      options={data || []}
      getOptionLabel={(o) => o.name}
      renderInput={(params) => <TextField {...params} label={props.label} />}
      loading={!data}
      multiple
      onChange={(event, value) =>
        value.length > 0
          ? setValue(value.map((v) => v.id))
          : setValue(undefined)
      }
    />
  );
};
