import UpdateIcon from "@mui/icons-material/Update";
import type { SvgIconTypeMap } from "@mui/material";
import type { DefaultComponentProps } from "@mui/material/OverridableComponent";
import { useRecordContext } from "react-admin";
import type { DemandResponse } from "../types";

export const ShiftField = (
  props: { label?: string } & DefaultComponentProps<SvgIconTypeMap>
) => {
  const record = useRecordContext<DemandResponse>();

  if (!record?.isShift) {
    return null;
  }

  return <UpdateIcon color="primary" {...props} />;
};
