import { Box, Chip, Stack, Tabs } from "@mui/material";
import { blue, brown } from "@mui/material/colors";
import {
  Datagrid,
  DateField,
  FilterButton,
  FunctionField,
  Link,
  List,
  ReferenceArrayField,
  ReferenceField,
  TextField,
  WrapperField,
} from "react-admin";
import { TimeAgoField } from "../../../fields/TimeAgoField";
import { useURLFilter } from "../../../filters/useURLFilter";
import { ActionsMenu } from "../../../layout/actions-menu";
import { IncidentsOverTime } from "../../../layout/dashboard/widgets/IncidentsOverTime";
import TagArrayField from "../../device-tags/fields/TagArrayField";
import Aside from "../../devices/show/aside/Aside";
import { DataTab } from "../../devices/show/tabs/data";
import { CreateIncident } from "../create/CreateIncident";
import { IncidentActionMenu } from "../edit/ActionsMenu";
import { AssignedToField } from "../fields/AssignedToField";
import { IncidentHistory } from "../fields/Events";
import { IncidentTypeField } from "../fields/IncidentTypeField";
import { IncidentTag } from "../fields/Tag";
import { IncidentStatus } from "../types";
import type { Incident } from "../types";
import { IncidentStatusTab } from "./IncidentStatusTab";
import { ExportIncidents } from "./actions/Export";
import { incidentFilters } from "./filters";

export const IncidentList = () => {
  const [status, setStatus] = useURLFilter("status");
  return (
    <>
      <List
        perPage={20}
        sort={{ field: "timeStart", order: "DESC" }}
        filter={{ status: status ?? IncidentStatus.OPENED }}
        filters={incidentFilters}
        actions={
          <>
            <FilterButton />
            <ActionsMenu>
              <CreateIncident />
              <ExportIncidents />
            </ActionsMenu>
          </>
        }
      >
        <IncidentsOverTime />
        <Tabs
          value={status ?? IncidentStatus.OPENED}
          onChange={(_, value) => {
            setStatus(value);
          }}
          variant="fullWidth"
        >
          <IncidentStatusTab
            status={IncidentStatus.OPENED}
            value={IncidentStatus.OPENED}
          />
          <IncidentStatusTab
            status={IncidentStatus.PROCESSING}
            value={IncidentStatus.PROCESSING}
          />
          <IncidentStatusTab
            status={IncidentStatus.SOLVED}
            value={IncidentStatus.SOLVED}
          />
          <IncidentStatusTab
            status={IncidentStatus.CLOSED}
            value={IncidentStatus.CLOSED}
          />
        </Tabs>
        <Datagrid
          bulkActionButtons={false}
          expand={
            <ReferenceField reference="devices" source="deviceId" link={false}>
              <Box display="flex">
                <Box flexGrow={1}>
                  <DataTab />
                </Box>
                <Aside />
              </Box>
            </ReferenceField>
          }
        >
          <TimeAgoField source="createdAt" label="Start Date" />
          <TimeAgoField source="updatedAt" label="Last Update" />
          <FunctionField<Incident>
            label="Device"
            render={(record) => (
              <>
                <Link to={`/devices/${record.device?.id}/show`}>
                  {record?.device?.name}
                </Link>
              </>
            )}
          />
          <DateField
            label="Installation Date"
            source="device.installationDate"
            sortable={false}
          />
          <AssignedToField label="Assigned To" />
          <TextField label="priority" />
          <WrapperField label="Tags">
            <FunctionField<Incident>
              label="Tags"
              render={(record) =>
                record?.tags?.map((tag) => (
                  <IncidentTag key={tag} value={tag} />
                ))
              }
            />
            <FunctionField<Incident>
              render={(incident) =>
                incident?.device?.endUsers.length ? null : (
                  <Chip
                    label="Pas de résident"
                    sx={{
                      backgroundColor: brown["100"],
                      color: brown["800"],
                      borderRadius: 1,
                      m: 0.5,
                      height: 22,
                    }}
                  />
                )
              }
            />
            <FunctionField<Incident>
              render={(incident) =>
                incident?.device?.is_pilotable ? null : (
                  <Chip
                    label="Non Pilotable"
                    sx={{
                      backgroundColor: blue["100"],
                      color: blue["800"],
                      borderRadius: 1,
                      m: 0.5,
                      height: 22,
                    }}
                  />
                )
              }
            />
            <ReferenceArrayField
              label="Tags"
              reference="device-tags"
              source="device.tags"
            >
              <TagArrayField />
            </ReferenceArrayField>
          </WrapperField>
          <IncidentTypeField label="Type" />
          <WrapperField>
            <Stack direction="row">
              <IncidentHistory />
              <IncidentActionMenu />
            </Stack>
          </WrapperField>
        </Datagrid>
      </List>
    </>
  );
};
