import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { sortBy } from "lodash";

dayjs.extend(weekday);

export interface Instruction {
  setPoint: number;
  hysteresis: number;
  setPoint2?: number;
  hysteresis2?: number;
  time: {
    weekDay: number | "*";
    hour: number;
    minute: number;
  };
}

export const getScheduleSeries = (
  schedule: Instruction[],
  startDate: Date,
  endDate: Date
) => {
  let series: any[] = [];

  const computedSchedule = schedule.reduce((s, instruction) => {
    if (instruction.time.weekDay === "*") {
      s.push(
        ...Array.from({ length: 7 })
          .fill(null)
          .map((_, index) => ({
            setPoint: instruction.setPoint,
            hysteresis: instruction.hysteresis,
            time: {
              weekDay: index,
              hour: instruction.time.hour,
              minute: instruction.time.minute,
            },
          }))
      );
    } else {
      s.push(instruction);
    }
    return s;
  }, [] as Instruction[]);

  for (const instruction of computedSchedule) {
    let i = 0;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const date = dayjs(startDate)
        .weekday(instruction.time.weekDay as number)
        .hour(instruction.time.hour)
        .minute(instruction.time.minute)
        .add(i, "week");
      if (date.isBefore(startDate)) {
        i++;
        continue;
      }

      if (date.isAfter(endDate)) {
        break;
      }

      series.push({
        x: date.unix() * 1000,
        y: instruction.setPoint,
        custom: instruction,
      });
      i++;
    }
  }

  series = sortBy(series, (point) => point.x);

  if (series.length === 0) {
    return series;
  }

  series = [
    {
      ...series.at(-1),
      x: startDate.getTime(),
    },
    ...series,
    {
      ...series.at(-1),
      x: endDate.getTime(),
    },
  ];

  return series;
};
