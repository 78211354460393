import { CountEnumChart } from "../../../layout/dashboard/widgets/CountEnumChart";
import { useInstallationPieFilter } from "./useInstallationPieFilter";

export const RefusalReasonsStats = ({
  entity,
}: {
  entity: "deal" | "project";
}) => {
  const { counts, filteredValue, toggleFilter } = useInstallationPieFilter(
    "refusalReason",
    entity
  );

  return (
    <CountEnumChart
      title="Raison des refus d'installation"
      onClick={(point) => toggleFilter(point.name)}
      data={
        counts &&
        Object.keys(counts).map((reason) => ({
          name: reason,
          y: counts[reason],
          sliced: filteredValue === reason,
        }))
      }
    />
  );
};
