import { Box, Typography } from "@mui/material";
import {
  Datagrid,
  DateField,
  ReferenceManyField,
  Show,
  TextField,
} from "react-admin";
import { InlineLabel } from "../../layout/InlineLabel";
import { communicationFields } from "./fields";

export const CommunicationShow = () => {
  return (
    <Show>
      <Box padding={2}>
        <InlineLabel label="Date">{communicationFields.date}</InlineLabel>
        <InlineLabel label="Topic">{communicationFields.topic}</InlineLabel>
        <InlineLabel label="Medium">{communicationFields.medium}</InlineLabel>
        <InlineLabel label="Status">{communicationFields.status}</InlineLabel>
        <InlineLabel label="Link">{communicationFields.externalId}</InlineLabel>
        <InlineLabel label="Housing">{communicationFields.housing}</InlineLabel>
        <InlineLabel label="Resident">
          {communicationFields.resident}
        </InlineLabel>
        <InlineLabel label="Project">{communicationFields.project}</InlineLabel>
        <InlineLabel label="Organization">
          {communicationFields.organization}
        </InlineLabel>
        <InlineLabel label="Maintenance Provider">
          {communicationFields.maintenanceProvider}
        </InlineLabel>
        <InlineLabel label="Intervention">
          {communicationFields.intervention}
        </InlineLabel>
        <ReferenceManyField
          reference="business-events"
          target="referenceId"
          sort={{ field: "ts", order: "ASC" }}
          filter={{ type: "COMMUNICATION_STATUS_CHANGE" }}
          perPage={1000}
        >
          <Typography variant="body1" fontWeight="bold">
            Status history:
          </Typography>
          <Datagrid bulkActionButtons={false}>
            <DateField label="Date" source="ts" showTime />
            <TextField label="Type" source="type" />
            <TextField label="Status" source="payload.status" />
            <TextField label="Details" source="payload.statusDetails" />
            <TextField label="Source" source="source" />
          </Datagrid>
        </ReferenceManyField>
      </Box>
    </Show>
  );
};
