import {
  ReferenceManyField,
  Pagination,
  Datagrid,
  DateField,
  FunctionField,
  TextField,
  ShowButton,
} from "react-admin";
import { RoundedNumberField } from "../../../../fields/RoundedNumberField";
import { CancelledField } from "../../../demand-response/fields/CancelledField";
import { ShiftField } from "../../../demand-response/fields/ShiftField";
import type { DemandResponsePool } from "../../../demand-response-pools/types";

export const DemandResponseEntityPoolsTab = () => {
  return (
    <ReferenceManyField
      reference="demand-response-pools"
      target="entity"
      pagination={<Pagination />}
      label={false}
    >
      <Datagrid bulkActionButtons={false} expand={<ExpandPool />}>
        <DateField
          source="startDate"
          label="Start date"
          showTime={true}
          variant="body1"
        />
        <DateField
          source="endDate"
          label="End date"
          showTime={true}
          variant="body1"
        />
        <FunctionField
          label="# PDLs"
          variant="body1"
          render={(record: DemandResponsePool) => record && record.pdls.length}
        />
        <TextField label="# Effacements" source="demandResponseStats.count" />
        <TextField
          label="# Effacements analysables"
          source="demandResponseStats.analyzableCount"
        />
        <RoundedNumberField
          label="Prix de vente moyen (€)"
          source="demandResponseStats.avgPriceAmount"
        />
        <RoundedNumberField
          label="Total ventes (€)"
          source="demandResponseStats.totalPriceAmount"
        />
        <RoundedNumberField
          label="Profits totaux (€)"
          source="demandResponseStats.totalProfits"
        />
        <RoundedNumberField
          label="Puissance déclarée moyenne (MW)"
          source="demandResponseStats.avgPowerDeclared"
        />
        <RoundedNumberField
          label="Efficacité moyenne"
          source="demandResponseStats.avgEfficiency"
        />
        <RoundedNumberField
          label="Précision déclarée moyenne"
          source="demandResponseStats.avgAccuracyDeclared"
        />
        <RoundedNumberField
          label="Précision réalisée moyenne"
          source="demandResponseStats.avgAccuracyRealized"
        />
        <TextField source="method" label="Méthode" />
      </Datagrid>
    </ReferenceManyField>
  );
};

const ExpandPool = () => {
  return (
    <ReferenceManyField
      reference="demand-responses"
      target="pool"
      label="Effacements"
      pagination={<Pagination />}
    >
      <Datagrid bulkActionButtons={false}>
        <DateField
          source="startDate"
          label="Start date"
          showTime={true}
          variant="body1"
        />
        <DateField
          source="endDate"
          label="End date"
          showTime={true}
          variant="body1"
        />
        <CancelledField label="Cancelled?" />
        <ShiftField label="Shift?" />
        <RoundedNumberField source="priceAmount" label="Price amount" />
        <RoundedNumberField source="efficiency" label="Efficiency" />
        <RoundedNumberField source="powerDeclared" label="Power declared" />
        <RoundedNumberField source="powerValorized" label="Power valorized" />
        <RoundedNumberField
          source="avoidedCO2WithAverageMethodInKg"
          label="Avoided CO2 in kg (average)"
        />
        <RoundedNumberField
          source="avoidedCO2WithMarginalMethodInKg"
          label="Avoided CO2 in kg (marginal)"
        />
        <ShowButton />
      </Datagrid>
    </ReferenceManyField>
  );
};
